body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plain {
	text-decoration: none;
	color: rgba(0,0,0,0.3);
}

.blinking {
    animation: blink 1s infinite;
    /*text-decoration: blink;*/
}
@keyframes blink {
   0% { opacity: 1; }
   50% { opacity: 1; }
   51% { opacity: 0; }
   100% { opacity: 0; }
}